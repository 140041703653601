.contact-us-page {
  padding: 20px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  background: #1d2a1d;
  .wrap {
    .page-title {
      font-family: "Neue Leiden Regular";
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      color: #fff;
    }
    .form-block {
      background: #ffffff;
      border: 0.25px solid #888888;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
      .input-field {
        margin-bottom: 14px;
        .field-tag {
          font-family: "Aeonik Regular";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 145%;
          color: #000000;
          margin-bottom: 8px;
        }
        .txt {
          font-family: "Aeonik Regular";
          padding: 8px;
          border: 0.25px solid #888888;
          border-radius: 8px;
        }
      }

      .actions {
        gap: 20px;
        .btn-login {
          border-radius: 30px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #000000;
          padding: 10px 30px;
        }
      }
    }
    .contact-us-btm {
      margin-top: 60px;
      .btm-description {
        font-family: "Aeonik Light";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        text-align: center;
      }
      .code-block {
        margin: 38px 0;
        background: #fff;
        border-radius: 8px;
        padding: 36px 28px;
        gap: 30px;
        @include media("<=phoneH") {
          padding: 20px 18px;
          gap: 20px;
        }
        .code {
          // overflow: hidden;
          border: 1px solid #888888;
          padding: 8px;
          border-radius: 8px;
        }
        .btn-copy {
          border-radius: 30px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #000000;
          padding: 8px 60px;
          @include media("<=phoneH") {
            padding: 8px 20px;
          }
        }
      }
    }
  }
}
