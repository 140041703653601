.about-us-page {
  padding: 0px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;

  .about-hero-sec {
    width: 100%;
    background-color: #1d2a1d !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 24px 0;

    .page-title {
      font-family: "Neue Leiden Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 67px;
      text-align: center;
      color: #fff;
      margin-bottom: 20px;

      @include media("<=phoneH") {
        font-size: 30px;
        margin-bottom: 0px;
      }
    }

    .desc {
      font-family: "Aeonik Regular";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #fff;

      @include media("<=phoneH") {
        font-size: 14px;
      }
    }
  }

  .sec-tag {
    font-family: "Neue Leiden Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 110%;
    color: black;

    @include media("<=phoneH") {
      font-size: 28px;
      line-height: 27px;
      margin-bottom: 12px;
      text-align: center;
    }
  }

  .page-block1 {
    padding: 0px 0;
    background-color: #1d2a1d;

    .wrap {
      .statement-sec {
        gap: 30px;

        @include media("<=phoneH") {
          flex-direction: column;
        }

        .left {
          flex: 1;

          .sec-tag {
            font-family: "Neue Leiden Regular";
            font-style: normal;
            font-weight: 500;
            font-size: 48px;
            line-height: 67px;
            color: #fff;
            margin-bottom: 20px;

            @include media("<=phoneH") {
              font-size: 28px;
              line-height: 27px;
              margin-bottom: 12px;
            }
          }

          .sec-desc {
            font-family: "Aeonik Light";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #fff;
          }
        }

        .right {
          flex: 0.8;

          .bitcoin-img {
            width: 100%;
            max-width: 400px;

            @include media("<=phoneH") {
              height: 250px;
            }
          }
        }
      }

      .grid-sec {
        .grid-sec-hdr {
          border: 2px solid red;

          .sec-tag {
            font-family: "Neue Leiden Regular";
            font-style: normal;
            font-weight: 500;
            font-size: 48px;
            line-height: 67px;
            color: black;

            @include media("<=phoneH") {
              font-size: 28px;
              line-height: 27px;
              margin-bottom: 12px;
              text-align: center;
            }
          }

          .sec-desc {
            font-family: "Aeonik Light";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #fff;

            @include media("<=phoneH") {
              text-align: center;
            }
          }
        }

        .grid-block {
          grid-gap: 40px;
          display: grid;
          width: 100%;
          height: 100%;
          grid-template-columns: repeat(4, 1fr);
          // padding: 40px 0 0;

          @include media("<=tablet") {
            grid-template-columns: repeat(2, 1fr);
          }

          @include media("<=phoneH") {
            grid-gap: 20px;
            grid-template-columns: repeat(2, 1fr);
          }

          .grid-item {
            min-height: 260px;
            width: 100%;
            padding: 12px;
            overflow: hidden;

            .img {
              height: 100px;
              width: 100px;
              border-radius: 50%;

              @include media("<=phoneH") {
                height: 90px;
                width: 90px;
              }
            }

            .grid-desc {
              font-family: "Aeonik Light";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: #fff;
              text-align: center;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }

  .mission-statement {
    background: #fff;

    .statement-sec {
      .sec-tag,
      .sec-desc {
        color: #000 !important;
      }
    }
  }
}
