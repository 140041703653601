.dashboard-sidebar {
  top: 45px;
  bottom: 0;
  left: 0;
  width: 280px;
  background: #1D2A1D;
  padding: 120px 30px;
  .side-block {
    .nave-link {
      color: #fff;
      text-align: center;
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      padding: 20px 30px;
      margin: 20px 0;
      @include anim;
      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        background: #FAA334;
        border-radius: 30px;
        font-weight: 700;
        color: #000;
      }
      &:hover {
        border-radius: 30px;
        background: $themeColor;
        color: #000;
      }
      &:after{
        position: absolute;
        content: '';
        border-bottom: 1px solid #000;
        width: 100%;
        left: 0;
        top: 80px;
      }
    }
  }
}

