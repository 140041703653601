.faqs-page {
  width: 100%;
  height: 100%;
  min-height: 730px;
  padding: 40px 0;
  background-color: #fff;
  .wrap {
    .sec-title {
      font-family: "Neue Leiden Regular";
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      color: #000000;
      @include media("<=phoneH") {
        font-size: 26px;
      }
    }
    .faqs-block {
      .faq-item {
        border-bottom: 1px solid #000000;
        padding: 12px 0;
        &:first-child {
          border-top: 1px solid #000000;
        }
        @include media("<=phoneH") {
          padding: 0px 0;
        }
        .item-hdr {
          padding: 10px 0;
          .qustion {
            flex: 1;
            font-family: "Aeonik Regular";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            color: #000000;
            @include media("<=phoneH") {
              font-size: 18px;
            }
          }
          .icon {
            svg {
              path {
                fill: #000;
              }
            }
            &:hover {
              svg {
                path {
                  fill: $themeColor;
                }
              }
            }
          }
        }
        .answer {
          display: none;
          font-family: "Aeonik Light";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #000000;
          &.show {
            display: flex;
            padding: 12px 0;
          }
        }
      }
    }
  }
}
