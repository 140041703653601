.signup-page {
  width: 100%;
  height: 100%;
  min-height: 730px;
  padding: 60px 0;
  background-color: #fff;

  .wrap {
    .page-title {
      font-family: "Aeonik Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      margin-bottom: 12px;
    }
    .form-block {
      background-color: #fff;
      border: 0.25px solid #888888;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
      .input-field {
        margin-bottom: 14px;
        .field-tag {
          font-family: "Aeonik Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 145%;
          color: #000000;
          margin-bottom: 8px;
        }
        .txt {
          font-family: "Aeonik Regular";
          padding: 8px;
          border: 0.25px solid #888888;
          border-radius: 8px;
        }
      }
      .terms-field {
        @include media("<=phoneH") {
          display: flex;
          align-items: center;
          font-size: 12px;
          width: max-content;
          word-wrap: break-word;
        }
        .thin-txt {
          font-family: "Aeonik Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 145%;
          color: #000000;
          @include media("<=phoneH") {
            font-size: 12px;
            margin: 0 3px;
          }
        }
      }
      .actions {
        gap: 20px;
        .btn-login {
          border-radius: 30px !important;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #000000;
          padding: 10px 30px;
        }
      }
    }
  }
}

.sms-verification-page {
  background-color: #fff;
  width: 100%;
  height: 100%;
  min-height: 730px;
  padding: 60px 0;
  .wrap {
    .page-title {
      font-family: "Aeonik Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      margin-bottom: 12px;
    }
    .form-block {
      background-color: #fff;
      border: 0.25px solid #888888;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
      .input-field {
        margin-bottom: 14px;
        .field-tag {
          font-family: "Aeonik Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 145%;
          color: #000000;
          margin-bottom: 8px;
        }
        .txt {
          font-family: "Aeonik Regular";
          padding: 8px;
          border: 0.25px solid #888888;
          border-radius: 8px;
        }
      }

      .actions {
        gap: 20px;
        .btn-login {
          border-radius: 30px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #000000;
          padding: 10px 30px;
        }
      }
    }
  }
}

.account-success-page {
  background-color: #1d2a1d;
  width: 100%;
  height: 70vh;
  min-height: 530px;
  padding: 40px 0;
  .wrap {
    .success-block {
      .coins-img {
        height: 150px;
        width: 150px;
      }
      .tag {
        font-family: "Neue Leiden Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 46px;
        line-height: 145%;
        text-align: center;
        color: #fff;
        margin: 30px 0;
        @include media("<=phoneH") {
          font-size: 26px;
        }
      }
      .btn-back {
        font-family: "Aeonik Regular";
        border-radius: 38px;
        border-color: #fff;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 145%;
        background-color: #fff;
        color: #000;
      }
    }
  }
}
