@import "./Colors.scss";
@import "./Props.scss";
@import "./media.scss";
@import "../components/Header/style.scss";
@import "../components/Footer/style.scss";
@import "../components/Sidebar/style.scss";
@import "../components/PagesMenuSidebar/pages-menu-sidebar.scss";
@import "../Pages/Login/style.scss";
@import "../Pages/Registration/style.scss";
@import "../Pages/Home/style.scss";
@import "../Pages/Terms/terms-style.scss";
@import "../Pages/AboutUs/about-us-style.scss";
@import "../Pages/BitcoinBackedLoans/bitcoin-backend-loadn.scss";
@import "../Pages/Security/security.scss";
@import "../Pages/Careers/careers.scss";
@import "../Pages/ContactUs/contact-us.scss";
@import "../Pages/Blogs/blog-page.scss";
@import "../Pages/RiskManagement/risk-management.scss";
@import "../Pages/FAQs/faqs-page.scss";
// User Dashboard CSS
@import "../Pages/User/PersonalInfo/dashboard-style";
@import "../components/DashboardSidebar/dashboard-sidebar.scss";

@font-face {
  font-family: "Aeonik Light";
  src: url("../assets/fonts/aeonik/Aeonik-Light.otf");
  font-style: light;
  font-weight: 300;
}

@font-face {
  font-family: "Aeonik Medium";
  src: url("../assets/fonts/aeonik/Aeonik-Medium.otf");
  font-style: medium;
  font-weight: 400;
}

@font-face {
  font-family: "Aeonik Regular";
  src: url("../assets/fonts/aeonik/Aeonik-Regular.otf");
  font-style: regular;
  font-weight: 500;
}

@font-face {
  font-family: "Aeonik Bold";
  src: url("../assets/fonts/aeonik/Aeonik-Bold.otf");
  font-style: bold;
  font-weight: 600;
}

@font-face {
  font-family: "Aeonik Thin";
  src: url("../assets/fonts/aeonik/Aeonik-Thin.otf");
  font-style: thin;
  font-weight: 300;
}

.font,
body {
  font-family: "Aeonik Regular";
}

input,
select {
  font-family: "Aeonik Medium";
}

@font-face {
  font-family: "Neue Leiden Light";
  src: url("../assets/fonts/neueLeiden/NeueLeiden-Light.ttf");
  font-style: light;
  font-weight: 300;
}

@font-face {
  font-family: "Neue Leiden Regular";
  src: url("../assets/fonts/neueLeiden/NeueLeiden-Regular.ttf");
  font-style: regular;
  font-weight: 400;
}

@font-face {
  font-family: "Neue Leiden Medium";
  src: url("../assets/fonts/neueLeiden/NeueLeiden-Medium.ttf");
  font-style: medium;
  font-weight: 500;
}

@mixin bgImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

.wrapWidth {
  width: 1250px;
  margin: 0 auto;

  @include media("<=xlarge") {
    width: 1250px;
    margin: 0 auto;
  }

  @include media("<=large") {
    width: 100%;
    padding: 0px 3%;
  }

  @include media("<=desktop") {
    width: 100%;
    padding: 0px 3%;
  }

  @include media("<=tablet") {
    width: 100%;
    padding: 0px 3%;
  }

  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
  }
}

.App {
  min-height: 100vh;
}

//Drop Down
.dropDown {
  width: 100%;

  .category {
    width: 100%;

    .cbox {
      // background: #000403;
      border: 1px solid #bababa;
      padding: 0 16px;
      min-height: 42px;
      border-radius: 5px;
      box-sizing: border-box;
      min-width: 180px;
      width: 100%;
      justify-content: space-between;

      @include media(">=phone", "<=480px") {
        min-width: 150px;
      }

      .icon-arrow {
        svg {
          path {
            fill: $themeColor;
          }
        }
      }

      .slt {
        overflow: hidden;

        .ico {
          cursor: pointer;
          color: #000000d9;
          height: 22px;
          display: flex;
          align-items: center;
        }

        .lbl {
          color: #000;
          padding: 0 10px;
        }

        .unit-name {
          .unit-eng {
            color: #000000;
          }
        }
      }

      .d-img {
        margin: 2px 10px;

        .img {
          height: 25px;
          width: 25px;
          padding: 2.5px;
          border-radius: 100%;
          border: 1px solid $themeColor;
        }
      }

      .edit-icon {
        margin: 2px 10px;
        padding: 4px;
        border-radius: 100%;
        border: 1px solid $themeColor;
      }

      .dropDown-icon {
        cursor: pointer;
      }
    }
  }

  .block {
    top: 45px;
    left: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid $themeColor;
    background: #000403;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    width: 100%;

    .manue {
      width: 100%;
      // height: 150px;
      overflow-y: auto;
      padding: 0px 0px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      .slt {
        width: 100%;
        padding: 8px 16px;
        @include anim;

        &.active {
          background-color: $themeColor;
          color: #242424;

          .unit-name {
            .unit-eng {
              color: #242424;
            }
          }
        }

        .unit-name {
          .unit-eng {
            color: #eee;
          }
        }

        .ico {
          justify-content: flex-start;
          margin-right: 8px;
          width: 25px;
          height: 25px;
        }

        .lbl {
          flex: 1;
          margin-left: 5px;
          justify-content: flex-start;
        }

        &:hover {
          background-color: #1e4231;
          cursor: pointer;

          .ico {
            color: $themeColor;
          }

          .lbl {
            color: $themeColor;
          }
        }
      }
    }

    &.show {
      display: flex;
      visibility: visible;
      opacity: 1;
      @include dropDownAnim;
    }
  }
}

// Wrapper
.dashboard-page {
  min-height: 730px;

  .wrap {}
}

.terms-dashboard-page {
  min-height: 730px;

  .pages-block {
    .pages-menu-sidebar {
      @include media("<=phoneH") {
        display: none;
      }
    }
  }

  .terms-pages {
    @include media("<=phoneH") {
      margin-left: 0;
    }

    .pages-selection-dropdown {
      display: none;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 30px 20px;

      @include media("<=phoneH") {
        display: flex;
      }
    }
  }
}

.loader {
  background-color: #fff;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
  }
}

.ticker-container {
  display: flex;
  height: 35px;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background-color: #f9c46e;

  .ticker-items {
    display: inline;
    padding: 10px;
  }
}

.tab_active_color {
  color: white;
  background-color: #FAA334 !important;
}

.tab_color {
  color: black;
  background-color: rgba(249, 196, 110, 0.75);
}

input {
  text-align: left;
}

th input {
  text-align: center;
}

td {
  input {
    text-align: center;
  }
}

th,
td {
  text-align: left !important;
}

.marginLeft300 {
  margin-left: 300px;
}

.m_padding32 {
  padding-left: 16rem;
}

.Sweet_cancel_modal {
  p {
    line-height: 28px;
  }

  .right-text {
    text-align: start;
  }

  .left-text {
    text-align: end;
  }
}

.cancel_modal {
  width: 33%;

  h2 {
    font-weight: 300;
  }
}

div:where(.swal2-container) .swal2-html-container {
  margin: 1em 0.3em !important;
}

.adress_flex {
  label {
    flex-basis: 100%;
  }
}

.chart-label-container {
  width: 100px;
  position: relative;
  top: 15px;
  left: 10px;
}

@media only screen and (max-width: 600px) {
  .marginLeft300 {
    margin-left: 0;
    margin-top: 30px;
  }

  .adress_flex {
    flex-direction: column;

    input {
      margin: 10px 0px;
    }
  }

  .m_flex_direction {
    flex-direction: column;
  }

  .m_align_center {
    align-items: center;
  }

  .m_width_100 {
    width: 100%;
  }

  .m_search_btn {
    width: 50%;
    margin-top: 10px;
  }

  .wallets_inputs {
    input {
      width: 100%;
      margin: 10px 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .dashboard-sidebar {
    left: -290px;
    transition: 0.3s;
    z-index: 1;
  }

  .dashboard-sidebar.active {
    left: 0 !important;
  }

  .dashboard_sidebar_btn {
    position: absolute;
    top: 150px;
    left: 10px;
  }

  .dashboard_sidebar_btn_close {
    z-index: 1;
    width: 12%;
    color: #FAA334;
    right: 3px;
    border-radius: 5px;
    padding: 8px 17px;
    background: #1D2A1D;
    cursor: pointer;
    position: absolute;
    top: 150px;
    left: 75%;
  }

  .dashboard_sidebar_btn_close.active {
    display: block;
  }

  .m_padding32 {
    padding-left: 0;
  }

  .PieChart {
    width: 100%;
    height: 150px;
  }
}