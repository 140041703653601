.bitcoin-backed-loans-page {
  padding: 0px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;

  .page-hero-sec {
    width: 100%;
    // min-height: 350px;
    padding: 24px 0;
    background: #1d2a1d;

    .wrap {
      .sec-title {
        font-family: "Neue Leiden Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        color: #fff;

        @include media("<=phoneH") {
          font-size: 26px;
        }
      }

      .sec-desc {
        font-family: "Aeonik Light";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #fff;
        margin: 30px 0;
        width: 40%;

        @include media("<=desktop") {
          width: 70%;
        }

        @include media("<=phoneH") {
          margin: 20px 0;
          width: 100%;
        }
      }

      .actions {
        gap: 20px;
        margin-top: 8px;

        .btn-loan {
          font-family: "Aeonik Regular";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
          border-radius: 48px;

          &:first-child {
            background: #ffffff;
            border-color: #ffffff;
            color: #000000;
          }

          &:last-child {
            background: $themeColor;
            border-color: #000000;
            color: #000;
          }
        }
      }
    }
  }

  .our-rates-section {
    padding: 24px 0;

    .wrap {
      .sec-title {
        font-family: "Neue Leiden Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        color: #000000;

        @include media("<=phoneH") {
          font-size: 26px;
        }
      }

      .rate-block {
        width: 100%;
        min-height: 150px;
        background: $themeColor;
        border-radius: 8px;
        padding: 20px;
        gap: 30px;
        overflow: hidden;
        margin: 20px 0;



        @include media("<=phoneH") {
          gap: 10px;
          flex-direction: column;
          page-break-after: auto;
        }

        .rate-item {
          flex: 1;

          @include media("<=phoneH") {}

          .i-val {
            font-family: "Neue Leiden Regular";
            font-style: normal;
            font-weight: 600;
            font-size: 38px;
            line-height: 130%;
            color: #000000;

            @include media("<=phoneH") {
              font-size: 22px;
              line-height: 100%;
            }
          }

          .i-desc {
            font-family: "Aeonik Regular";
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 145%;
            width: 50%;
            color: #000000;

            @include media("<=phoneH") {
              width: 95%;
            }
          }
        }
      }

      .points-block {
        .point-item {
          padding: 30px 0;
          border-bottom: 1px solid #000000;
          gap: 40px;

          &:last-child {
            border-bottom: 0;
          }

          @include media("<=phoneH") {
            flex-direction: column;
            padding: 20px 0;
            gap: 10px;
          }

          .left-side {
            gap: 40px;
            flex: 0.8;

            @include media("<=phoneH") {
              width: 100%;
              gap: 20px;
            }

            .img-box {
              .img {
                height: 60px;
                width: 60px;
                border-radius: 50%;
                background: #d9d9d9;
              }
            }

            .title {
              font-family: "Aeonik Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 125%;
              color: #000000;
            }
          }

          .right-side {
            flex: 1;

            @include media("<=phoneH") {
              width: 100%;
            }

            .desc {
              font-family: "Aeonik Light";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 145%;
              color: #000000;

              @include media("<=phoneH") {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .loan-calculator-section {
    width: 100%;
    padding: 24px 0;
    background: #1d2a1d;

    .wrap {
      .sec-title {
        font-family: "Neue Leiden Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        color: #fff;

        @include media("<=phoneH") {
          font-size: 26px;
        }
      }

      .sec-desc {
        font-family: "Aeonik Light";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #fff;
        //   margin: 30px 0;
      }

      .calculator-from-block {
        background: #ffffff;
        border-radius: 8px;
        padding: 30px 20px;
        margin: 12px 0;

        .grid-row3 {
          grid-gap: 20px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          @include media("<=phoneH") {
            grid-template-columns: repeat(1, 1fr);
          }

          .grid-item {
            .item-tag {
              font-family: "Aeonik Regular";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 145%;
              color: #000000;
              margin-bottom: 12px;
            }

            .item-field {
              border: 0.25px solid #888888;
              border-radius: 8px;
              padding: 6px 10px;
              @include anim;

              &:focus-within {
                border-color: $themeColor;
              }

              .txt {
                width: 100%;
              }

              .f-lbl {}
            }

            .val {
              font-family: "Aeonik Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 145%;
              color: #000000;
            }

            .f-desc {
              font-family: "Aeonik Light";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 145%;
              color: #000000;
              margin-top: 12px;
            }
          }
        }

        .action {
          .btn-get {
            border-radius: 48px;
            font-family: "Aeonik Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            width: max-content;
            padding: 8px 20px;

            @include media("<=phoneH") {
              margin-top: 14px;
            }
          }

          .a-desc {
            font-family: "Aeonik Light";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 145%;
            color: #000000;
            margin-top: 12px;
          }
        }
      }
    }
  }
}