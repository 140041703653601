.blog-post-card {
  min-height: 250px;
  width: 100%;
  background-color: #e2e2e2 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  // background-position: center !important;
  border-radius: 12px;
  overflow: hidden;
  @include anim;

  .card-overlay {
    inset: 0;
    border-radius: 12px;
    background: rgb(29, 42, 29, 0.4);

    .title {
      font-family: "Neue Leiden Light";
    }
  }

  &:hover {
    .card-overlay {
      background: rgb(29, 42, 29, 0.6);
      @include anim;
    }

    transform: scale(1.05);
  }
}

.blog-page {
  width: 100%;
  height: 100%;
  min-height: 730px;
  padding: 40px 0;
  background-color: #fff;

  .wrap {
    .sec-title {
      font-family: "Neue Leiden Regular";
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      color: #000000;

      @include media("<=phoneH") {
        font-size: 26px;
      }
    }

    .blog-post-card {
      margin-bottom: 20px;
    }

    .my-masonry-grid {
      display: -webkit-box;
      /* Not needed if autoprefixing */
      display: -ms-flexbox;
      /* Not needed if autoprefixing */
      display: flex;
      margin-left: -30px;
      /* gutter size offset */
      width: auto;
    }

    .my-masonry-grid_column {
      padding-left: 30px;
      /* gutter size */
      background-clip: padding-box;
    }

    /* Style your items */
    .my-masonry-grid_column > div {
      /* change div to reference your elements you put in <Masonry> */
      background: grey;
      margin-bottom: 30px;
    }
  }
}

.blog-detail-page {
  .page-hdr {
    padding: 40px 0;

    .title {
      font-size: 36px;
      color: #fff;
      font-family: "Neue Leiden Light";
    }
  }

  .page-description {
    padding: 30px 0;

    h1 {
      color: #000000;
      font-size: 26px;
      font-weight: 600;
      font-family: "Aeonik Light";
      margin-bottom: 6px;
    }

    p {
      color: #000000;
      font-size: 16px;
      line-height: 28px;
      font-weight: 300;
      font-family: "Aeonik Light";
      margin-bottom: 18px;
    }

    li {
      color: #000000;
      font-size: 16px;
      line-height: 28px;
      font-weight: 300;
      font-family: "Aeonik Light";
      margin-bottom: 18px;
    }
  }

  .related-articles-block {
    .rab-title {
      font-size: 30px;
      font-weight: 400;
      color: #000000;
      font-family: "Neue Leiden Light";
    }

    .related-articles-wrap {
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin: 10px 0 40px 0;

      @include media("<=tablet") {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media("<=phoneH") {
        grid-template-columns: repeat(1, 1fr);
      }

      .blog-post-card {
        .card-overlay {
          background: rgb(29, 42, 29, 0.8);

          .title {
            width: 90%;
            font-size: 22px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
