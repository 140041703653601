.table-container {
    @apply rounded-lg overflow-x-auto w-full;
}
.thead-tr {
    @apply text-left bg-gray-50;
}
.th {
    @apply p-4 uppercase text-xs font-normal text-gray-600 text-left;
}
.td {
    @apply p-4 text-sm text-gray-700;
}

/* admin data table css */
