// Header
.header-camp {
  z-index: 999;
  top: 43px;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 60px;
  padding: 16px 0;
  // padding: 16px 30px 16px 30px;
  position: sticky;
  background-color: #1d2a1d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  .wrap {
    width: 1250px;
    margin: 0 auto;
    gap: 30px;

    @include media("<=xlarge") {
      width: 1250px;
      margin: 0 auto;
    }

    @include media("<=large") {
      width: 100%;
      padding: 0px 3%;
    }

    @include media("<=desktop") {
      width: 100%;
      padding: 0px 3%;
    }

    @include media("<=tablet") {
      width: 100%;
      padding: 0px 4%;
    }

    @include media("<=phoneH") {
      padding: 0px 4%;
    }

    .left {
      // flex: 1;

      .logo-img {
        .logo {
          height: 38px;
        }
      }

      .menu-list {
        margin-left: 60px;
        gap: 40px;

        @include media("<=tablet") {
          display: none;
        }

        .menu-item {
          padding: 6px 0;
          cursor: pointer;
          @include anim;

          .lbl {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 145%;
            color: #ffffff;
            margin-right: 6px;
          }

          &:hover {
            .lbl {
              color: $themeColor;
            }

            .icon {
              svg {
                path {
                  fill: $themeColor;
                }
              }
            }
          }
        }

        // sub Menu Dropdown
        .sub-menu-list {
          background: $themeColor;
          width: 150px;
          position: absolute;
          top: 30px;
          right: -38px;
          padding: 0px;
          box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 16px;
          border-radius: 4px;
          max-height: 260px;
          overflow: hidden;
          overflow-y: auto;

          // border: 1px solid #fff;
          &.show {
            @include dropDownAnim;
          }

          ._sub-menus {
            width: 100%;
            padding: 0px;
            gap: 1px;

            ._sub-menu-item {
              padding: 12px 14px;
              @include anim;
              border-bottom: 1px solid #ffffff;

              .sub-menu-lbl {
                font-family: "Aeonik Regular";
                font-style: normal;
                // font-weight: 500;
                font-size: 14px;
                line-height: 145%;
                color: #000000;
              }

              &:hover {
                @include anim;

                .sub-menu-lbl {
                  color: #ffffff;
                }
              }

              &:last-child {
                border-bottom: 0;
              }
            }
          }

          .tag {
            color: #121212;
            font-size: 14px;
            font-weight: 500;
            padding: 6px 20px;
            cursor: pointer;

            &:hover {
              color: #ffffff;
              background: $themeColor;
            }
          }
        }
      }
    }

    .right {
      // flex: 0.3;
      flex: 1;

      .actions {
        gap: 20px;

        @include media("<=tablet") {
          display: none;
        }

        .btn-login {
          font-family: "Aeonik Regular";
          border-radius: 28px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 145%;
          color: #000000;
          padding: 8px 25px;

          &:last-child {
            background: #fff;
            color: #000000;
            border-color: #000000;
          }
        }
      }

      .Menu-Icon {

        @include media("<=tablet") {
          display: flex;
        }

        svg {
          path {
            fill: #ffffff;
          }
        }

        &:hover {
          cursor: pointer;

          svg {
            path {
              fill: $themeColor;
            }
          }
        }
      }
    }

  }
}

.currencyTicker {
  z-index: 999;
  top: 0;
  position: sticky;
}