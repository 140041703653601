.security-page {
  padding: 0px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;

  .page-hero-sec {
    width: 100%;
    padding: 60px 0;
    background: #1d2a1d;

    .wrap {
      .sec-title {
        font-family: "Neue Leiden Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        color: #fff;

        @include media("<=phoneH") {
          font-size: 26px;
        }
      }

      .sec-desc {
        font-family: "Aeonik Light";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #fff;
        width: 65%;

        @include media("<=tablet") {
          width: 100%;
        }
      }

      .actions {
        gap: 20px;
        margin-top: 8px;

        .btn-loan {
          font-family: "Aeonik Regular";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
          border-radius: 48px;

          &:first-child {
            background: #ffffff;
            border-color: #ffffff;
            color: #000000;
          }
        }
      }
    }
  }

  .our-rates-section {
    padding: 24px 0;
    background: #fff;

    .wrap {
      .sec-title {
        font-family: "Neue Leiden Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        color: #000;

        @include media("<=tablet") {
          font-size: 24px;
        }
      }

      .points-block {
        .point-item {
          padding: 30px 0;
          border-bottom: 1px solid #000000;
          gap: 40px;

          &:last-child {
            border-bottom: 0;
          }

          @include media("<=phoneH") {
            flex-direction: column;
            padding: 20px 0;
            gap: 10px;
          }

          .left-side {
            gap: 40px;
            flex: 0.8;

            @include media("<=phoneH") {
              width: 100%;
              gap: 20px;
            }

            .img-box {
              .img {
                height: 60px;
                width: 60px;
                border-radius: 50%;
                background: #d9d9d9;
              }
            }

            .title {
              font-family: "Aeonik Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 125%;
              color: #000;
            }
          }

          .right-side {
            flex: 1;

            @include media("<=phoneH") {
              width: 100%;
            }

            .desc {
              font-family: "Aeonik Light";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 145%;
              color: #000;

              @include media("<=phoneH") {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .bitcoin-lock-section {
    padding: 30px 0;
    background: #1d2a1d;
    background-size: contain;
    background-repeat: no-repeat;

    .bitcoin-blk {
      gap: 60px;

      .left {
        @include media("<=tablet") {
          display: none;
        }

        .lock-img {
          height: 285px;
        }
      }

      .right {
        flex: 1;

        .points-block {
          width: 100%;

          .point-item {
            padding: 30px 0;
            border-bottom: 1px solid #fff;

            &:last-child {
              border-bottom: 0;
            }

            @include media("<=phoneH") {
              padding: 20px 0;
              flex-direction: column;
            }

            .left-side {
              gap: 30px;

              .img-box {
                .img {
                  height: 50px;
                  width: 50px;
                  border-radius: 50%;
                  background: #fff;
                }
              }

              .title {
                font-family: "Aeonik Medium";
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 125%;
                color: #fff;
              }
            }

            .right-side {
              .desc {
                width: 65%;
                font-family: "Aeonik Light";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 145%;
                color: #fff;

                @include media("<=phoneH") {
                  width: 78%;
                }
              }
            }
          }
        }
      }
    }
  }
}
