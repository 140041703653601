.admin-dashboard-page {
  background-color: #fff;

  .dashboard-page {
    background-color: #fff;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 50px 40px;
    @include media("<=phoneH") {
      padding: 30px 20px;
    }

    .page-title {
      font-family: "Aeonik Regular";
      font-style: normal;
      font-size: 26px;
      line-height: 130%;
      color: #000000;
      margin-bottom: 15px;
      margin-left: 35px;
      @include media("<=phoneH") {
        font-size: 28px;
        line-height: 110%;
      }
    }

    .rounded {
      background-color: #fff;
    }

    p {
      margin-left: 35px;
    }

    .page-block {
      .content-list {
        .content-title {
          font-family: "Aeonik Regular";
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: #000000;
          margin-bottom: 30px;
        }

        .content-desc {
          font-family: "Aeonik Light";
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #000000;
          margin-bottom: 30px;
        }
      }
    }

    .css-1nmdiq5-menu {
      z-index: 99999 !important;
    }

    .file-inside {
      cursor: pointer;
      background: url(../../../../public/images/upload_icon.svg) no-repeat center;
      text-align: center;

      input {
        visibility: hidden;
      }

      p {
        margin-top: 45px;
        margin-left: 0;
      }
    }

    .btn-rounded {
      border-radius: 48px;
    }

    .plus_button {
      width: 100%;

      .add-more-btn {
        height: 50px;
        margin: 0 auto;
        background: url(../../../../public/images/upload_icon.svg) no-repeat center;
        text-align: center;
      }
    }
  }

  .identify_vid {
    border: 1px solid black;
    width: 100%;
    height: auto;
  }

  .terms_btn {
    button {
      margin: 10px;
      background: white;
      padding: 18px 20px;
      border-radius: 10px;
      border: solid 1px black;
      cursor: pointer;
    }

    .active_btn {
      background: #FAA334;
    }
  }
}

.govt-id-images {
  height: 200px;
  width: 300px;
  border-radius: 5px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  object-fit: cover;
}

.watch-btn {
  background: #FAA334;
  border-radius: 30px;
  color: #000;
  text-align: center;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 15px;
  @include anim;

  &:hover {
    border-radius: 30px;
    background: $themeColor;
    color: #000;
  }
}

.image_preview {
  height: 200px;
  width: 200px;
  object-fit: contain;
}

.remove_img_btn {
  color: #FAA334;
  position: absolute;
  right: 3px;
  top: 10px;
  border-radius: 5px;
  padding: 2px 10px;
  background: #1D2A1D;
  cursor: pointer;
}

.tabs {
  display: flex;
  justify-content: end;

  .customTabs {
    border-radius: 30px;
    margin: 10px;
    color: black;
    font-size: 14px;
    font-weight: 600;
  }

  .tabActive {
    background-color: #f9c46e;
    margin: 10px;
    color: black;
    opacity: 1;
  }
}

.bitcoin-backed-loans-panel {
  padding: 0px 0;
  width: 100%;
  height: 100%;
}

.loan-calculator-section-panel {
  width: 100%;
  padding: 0px 0;

  .wrap {
    .sec-title {
      font-family: "Neue Leiden Regular";
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      color: #000;
      @include media("<=phoneH") {
        font-size: 26px;
      }
    }

    .sec-desc {
      font-family: "Aeonik Light";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #fff;
      //   margin: 30px 0;
    }

    .calculator-from-block {
      background: #ffffff;
      border-radius: 8px;
      padding: 30px 20px;
      margin: 12px 0;

      .grid-row3 {
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @include media("<=phoneH") {
          grid-template-columns: repeat(1, 1fr);
        }

        .grid-item {
          .item-tag {
            font-family: "Aeonik Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 145%;
            color: #000000;
            margin-bottom: 12px;
          }

          .item-field {
            border: 0.25px solid #888888;
            border-radius: 8px;
            padding: 6px 10px;
            @include anim;

            &:focus-within {
              border-color: $themeColor;
            }

            .txt {
              width: 100%;
            }

            .f-lbl {
            }
          }

          .val {
            font-family: "Aeonik Medium";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 145%;
            color: #000000;
          }

          .f-desc {
            font-family: "Aeonik Light";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 145%;
            color: #000000;
            margin-top: 12px;
          }
        }
      }

      .action {
        .btn-get {
          border-radius: 48px;
          font-family: "Aeonik Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          width: max-content;
          padding: 8px 20px;
          @include media("<=phoneH") {
            margin-top: 14px;
          }
        }

        .a-desc {
          font-family: "Aeonik Light";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 145%;
          color: #000000;
          margin-top: 12px;
        }
      }
    }
  }
}

.noDataFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  h1 {
    font-size: 40px;
    color: black;
  }
}

table th, td, input {
  vertical-align: center;
  text-align: center;
}

// for 1366px screens
@media screen and (min-width: 1366px) and (max-width: 1650px) {
  th {
    padding: 5px 0px 5px 0px !important;
  }
}

.p-info-admin {
  input {
    //width: 300px
  }
}


div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: $themeColor !important;
  color: black !important;
  border-radius: 15px !important;
  padding: 10px 25px !important;
}

div:where(.swal2-container).swal2-center > .swal2-popup {
  padding: 50px !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 2.875em !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
  box-shadow: unset !important;
}

.custommodal {
  //display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  justify-content: center;
  align-items: center;

  .custommodal_modal-content {
    width: 33%;
    background-color: #fff;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    position: relative;

    .close {
      position: absolute;
      top: -14px;
      right: 14px;
      cursor: pointer;
      font-size: 50px;
      color: #f9c46e;
      font-weight: 700;
    }

    /* Close button (X) hover effect */
    .close:hover {
      color: #333;
    }

    .modal_innner {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .modal_title {
        text-align: center;
        font-size: 60px;
      }

      .modal_desc {
        text-align: center;
        margin: 0;
        font-size: 25px;
        padding: 0 10px 20px 10px;

      }

      .payment_div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .payment_button {
          background: #f9c46e;
          border: 1px #f9c46e solid;
          color: #000;
          display: table;
          text-align: center;
          padding: 8px 25px;
          cursor: pointer;
          border-radius: 48px;
        }

        .btc_payment {
          p {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}
