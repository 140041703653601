// Footer
.footer-comp {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 160px;
  padding: 30px 0px;
  z-index: 9999;

  .wrap {
    width: 1250px;
    margin: 0 auto;

    @include media("<=xlarge") {
      width: 1250px;
      margin: 0 auto;
    }

    @include media("<=large") {
      width: 100%;
      padding: 0px 3%;
    }

    @include media("<=desktop") {
      width: 100%;
      padding: 0px 3%;
    }

    @include media("<=tablet") {
      width: 100%;
      padding: 0px 5%;
      flex-direction: column;
      gap: 40px;
    }

    @include media("<=phoneH") {
      padding: 0px 5%;
      gap: 30px;
    }

    .f-left {
      @include media("<=phoneH") {
        flex: 1;
      }

      .footer-logo {
        height: 40px;
        margin-bottom: 16px;
      }

      .copy-write {
        font-family: "Aeonik Light";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
      }
    }

    .f-right {
      @include media("<=tablet") {
        flex: 0.5;
      }

      @include media("<=phoneH") {
        flex: 1;
      }

      .links-sec {
        gap: 80px;

        @include media("<=tablet") {
          flex-direction: column;
          gap: 20px;
          width: 100%;
        }

        .social-links {
          @include media("<=tablet") {
            border-bottom: 1px solid #ffffff;
            padding-bottom: 20px;

            &:last-child {
              border-bottom: none;
            }
          }

          .link {
            font-family: "Aeonik Light";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
            margin-bottom: 10px;
            @include anim;

            &:hover {
              color: $themeColor;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}