.pages-menu-sidebar {
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  background: #1D2A1D;
  padding: 120px 40px;
  .side-block {
    .nave-link {
      font-family: "Aeonik Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #fff;
      margin-bottom: 20px;
      @include anim;
      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        background: transparent;
        font-weight: 700;
        font-family: "Aeonik Bold";
      }
      &:hover {
        color: $themeColor;
      }
    }
  }
}
