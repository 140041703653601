.lading-page {
  padding: 0px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;

  .tv-right {
    background-size: cover;
  }

  .hero-sec {
    gap: 30px;
    padding: 0;

    @include media("<=tablet") {
      gap: 20px;
      padding: 24px 0;
    }

    @include media("<=phoneH") {
      flex-direction: column;
      padding: 24px 0;
    }

    .hero-left {
      flex: 1.3;
      padding: 24px 0;

      .meta {
        // padding-right: 46px;

        .subtitlehome {
          font-family: "Aeonik Regular";
          font-weight: 500;
          font-size: 16px;
          text-transform: uppercase;
          letter-spacing: 4px;
        }

        .slug {
          font-family: "Neue Leiden Light";
          font-style: normal;
          font-weight: 400;
          font-size: 58px;
          line-height: 130%;
          color: #000000;

          @include media("<=tablet") {
            font-size: 38px;
            line-height: 110%;
          }

          @include media("<=phoneH") {
            font-size: 24px;
            line-height: 90%;
          }
        }

        .desc {
          font-family: "Aeonik Light";
          font-style: normal;
          font-weight: 500;
          font-size: 19px;
          line-height: 145%;
          color: #000000;
          margin-top: 20px;
        }

        .btn-started {
          font-family: "Aeonik Regular";
          border-radius: 30px;
          width: max-content;
          margin-top: 20px;
          padding: 10px 20px;
        }
      }
    }

    .hero-right {
      flex: 1;

      @include media("<=tablet") {
        flex: 0.7;
      }

      .hero-img {
        width: 100%;
        height: auto;

        @include media("<=tablet") {
          width: 100%;
          height: auto;
        }

        @include media("<=phoneH") {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .learn-more-sec {
    // min-height: 304px;
    width: 100%;
    background: #1d2a1d;

    @include media("<=phoneH") {
      padding: 30px 0;
    }

    .wrap {
      .more-learn-meta {
        padding: 24px 0;
        gap: 40px;

        @include media("<=phoneH") {
          flex-direction: column-reverse;
        }

        .coin-img {
          .img {
            height: auto;
            width: 300px;
            object-fit: cover;

            @include media("<=tablet") {
              width: 460px;
            }

            @include media("<=phoneH") {
              // height: 160px;
              width: 160px;
            }
          }
        }

        .meta {
          .sec-slug {
            font-family: "Neue Leiden Medium";
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 100%;
            color: #fff;

            @include media("<=tablet") {
              font-size: 36px;
              line-height: 110%;
            }
          }

          .sec-desc {
            font-family: "Aeonik Light";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 145%;
            color: #fff;
            margin: 20px 0;
          }

          .btn-more {
            border-color: #fff;
            background: #fff;
            border-radius: 30px;
            width: max-content;
            font-family: "Aeonik Regular";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 145%;
            color: #000000;
          }
        }
      }
    }
  }

  .bit-coin-tv-sec {
    width: 100%;
    padding: 0px 0;

    .tv-left {
      flex: 1;

      .bitcoins-tv-info-list {
        .info-item {
          gap: 40px;
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
          }

          @include media("<=tablet") {
            margin-bottom: 20px;
          }

          @include media("<=phoneH") {
            gap: 20px;
          }

          .img-side {
            @include media("<=phoneH") {
              align-items: start;
            }

            .image {
              height: 80px;
              width: 80px;
              border-radius: 50%;
              background: #d9d9d9;
            }
          }

          .info-meta {
            .title {
              font-family: "Aeonik Regular";
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 145%;
              color: #000000;
              margin-bottom: 8px;
            }

            .desc {
              font-family: "Aeonik light";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 145%;
              color: #000000;
            }
          }
        }
      }
    }

    .tv-right {
      flex: 1;

      @include media("<=tablet") {
        display: none;
      }

      .tv-img {
        width: 90%;
        height: auto;
      }
    }
  }

  .numbers-sec {
    background: $themeColor;
    min-height: 144px;
    width: 100%;
    padding: 24px 0;

    .numbs-items {
      gap: 250px;

      .numb-item {
        width: 200px;

        @include media("<=phoneH") {
          width: 100px;
        }

        .numb {
          font-family: "Neue Leiden Regular";
          font-style: normal;
          font-weight: 600;
          font-size: 40px;
          line-height: 130%;
          text-align: center;
          color: #000000;

          @include media("<=phoneH") {
            font-size: 24px;
            line-height: 90%;
          }
        }

        .desc {
          font-family: "Aeonik Light";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 145%;
          text-align: center;
          color: #000000;

          @include media("<=phoneH") {
            line-height: 115%;
          }
        }
      }
    }
  }

  .handBitcoin-more-sec {
    min-height: 304px;
    width: 100%;
    background: #1d2a1d;
    padding: 24px 0;

    .wrap {
      .handBitcoin-learn-meta {
        @include media("<=phoneH") {
          flex-direction: column;
          gap: 30px;
        }

        .meta {
          .sec-slug {
            font-family: "Neue Leiden Medium";
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 100%;
            color: #fff;

            @include media("<=tablet") {
              font-size: 36px;
              line-height: 110%;
            }
          }

          .sec-desc {
            font-family: "Aeonik Light";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 145%;
            color: #fff;
            margin: 20px 0;
          }

          .btn-more {
            border-color: #050505;
            background: #fff;
            border-radius: 30px;
            width: max-content;
            font-family: "Aeonik Regular";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 145%;
            color: #000;
          }
        }

        .handBitcoin-img {
          padding: 10px 25px;

          @include media("<=tablet") {
            padding: 0px;
          }

          .img {
            height: auto;
            width: 642px;
            object-fit: cover;

            @include media("<=tablet") {
              height: auto;
              width: 1252px;
            }

            @include media("<=phoneH") {
              height: auto;
              width: 300px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .numbers-sec {
    .numbs-items {
      gap: 25px !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  .numbers-sec {
    .numbs-items {
      gap: 50px !important;
    }
  }
}
