.b2x-terms-page {
  background-color: #fff;
  padding: 0px 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 50px 40px;
  @include media("<=phoneH") {
    padding: 30px 20px;
  }
  .page-title {
    font-family: "Neue Leiden Medium";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 30px;
    @include media("<=phoneH") {
      font-size: 28px;
      line-height: 110%;
    }
  }
  .page-block {
    .content-list {
      .content-title {
        font-family: "Aeonik Regular";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #000000;
        margin-bottom: 30px;
      }
      .content-desc {
        font-family: "Aeonik Light";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        margin-bottom: 30px;
      }
    }
  }
}
