.careers-page {
  padding: 0px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;

  .page-hero-sec {
    width: 100%;
    min-height: 250px;
    padding: 40px 0;
    background: #1d2a1d;

    .wrap {
      .sec-title {
        font-family: "Neue Leiden Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        color: #fff;

        @include media("<=phoneH") {
          font-size: 28px;
        }
      }

      .sec-desc {
        font-family: "Neue Leiden Medium";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        text-transform: capitalize;
        color: #fff;
        margin: 10px 0;
        width: 50%;

        @include media("<=tablet") {
          width: 80%;
        }

        @include media("<=phoneH") {
          width: 100%;
        }
      }
    }
  }

  .graph-section {
    background: #fff;
    padding: 0px 0;

    .wrap {
      gap: 30px;

      .left {
        flex: 1;

        .sec-title {
          font-family: "Neue Leiden Regular";
          font-style: normal;
          font-weight: 500;
          font-size: 38px;
          color: #000000;
          margin-bottom: 20px;

          @include media("<=tablet") {
            font-size: 24px;
          }
        }

        .sec-tag {
          font-family: "Neue Leiden Medium";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          color: #000000;
          margin-bottom: 8px;

          @include media("<=phoneH") {
            font-size: 20px;
          }
        }

        .sec-desc {
          font-family: "Aeonik Light";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #000000;
          width: 80%;

          @include media("<=phoneH") {
            width: 100%;
          }
        }
      }

      .right {
        @include media("<=tablet") {
          display: none;
        }

        .crypto-img {
          width: 100%;
        }
      }
    }
  }

  .our-rates-section {
    padding: 24px 0;
    background: #fff;

    .wrap {
      .sec-title {
        font-family: "Neue Leiden Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        color: #000000;

        @include media("<=phoneH") {
          font-size: 26px;
        }
      }

      .points-block {
        .point-item {
          padding: 30px 0;
          border-bottom: 1px solid #000000;

          &:last-child {
            border-bottom: 0;
          }

          @include media("<=phoneH") {
            flex-direction: column;
            padding: 20px 0;
          }

          .left-side {
            gap: 40px;

            .img-box {
              .img {
                height: 68px;
                width: 68px;
                border-radius: 50%;
                background: #000;
              }
            }

            .title {
              font-family: "Aeonik Medium";
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 125%;
              color: #000000;
            }
          }

          .right-side {
            .desc {
              width: 50%;
              font-family: "Aeonik Light";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 145%;
              color: #000000;

              @include media("<=phoneH") {
                width: 70%;
              }
            }
          }
        }
      }
    }
  }

  .join-our-team-sec {
    background: #fff;
    padding: 24px 0;

    .btn-more {
      border-color: #f9c46e;
      background: #f9c46e;
      border-radius: 30px;
      width: max-content;
      font-family: "Aeonik Regular";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 145%;
      padding: 10px 20px;
      color: #000000;
    }

    .wrap {
      .sec-title {
        font-family: "Neue Leiden Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        color: #000000;

        @include media("<=phoneH") {
          font-size: 26px;
        }
      }

      .sec-desc {
        font-family: "Aeonik Light";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        margin: 30px 0;
        width: 95%;
      }
    }
  }
}
