.login-page {
  width: 100%;
  height: 100%;
  min-height: 730px;
  padding: 60px 0;
  background-color: #fff;
  .wrap {
    .page-title {
      font-family: "Aeonik Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      margin-bottom: 12px;
    }
    .form-block {
      background-color: #fff;
      border: 0.25px solid #888888;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
      .input-field {
        margin-bottom: 14px;
        .field-tag {
          font-family: "Aeonik Regular";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 145%;
          color: #000000;
          margin-bottom: 8px;
        }
        .txt {
          font-family: "Aeonik Medium";
          padding: 8px;
          border: 0.25px solid #888888;
          border-radius: 8px;
        }
      }
      .actions {
        gap: 20px;
        .btn-login {
          border-radius: 30px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #000;
          padding: 10px 30px;
          &:first-child {
            background: #000000;
            border-color: #000000;
            color: #fff;
          }

        }
      }
    }
  }
}
