@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./styles/buttons.css";
@import "./styles/form.css";
@import "./styles/styles.css";
@import "./styles/nav.css";
@import "./styles/header.css";
@import "./styles/table.css";

html {
  scroll-behavior: smooth;
}

/* .livecoinwatch-widget-1{
  width: 95% !important;
  height: 500px !important;
}  */