.risk-management-page {
  width: 100%;
  height: 100%;
  min-height: 730px;
  padding: 40px 0;
  background-color: #fff;
  .wrap {
    .sec-title {
      font-family: "Neue Leiden Regular";
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      color: #000000;
      @include media("<=phoneH") {
        font-size: 28px;
      }
    }
    .points-block {
      .point-item {
        padding: 30px 0;
        border-bottom: 1px solid #000000;
        @include media("<=phoneH") {
          flex-direction: column;
          padding: 20px 0;
          gap: 12px;
        }
        .left-side {
          gap: 20px;
          min-width: 400px;
          .img-box {
            .img {
              height: 68px;
              width: 68px;
              border-radius: 50%;
              background: #1e1e1e;
            }
          }
          .title {
            font-family: "Aeonik Regular";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 125%;
            color: #000000;
            width: 50%;
            @include media("<=phoneH") {
              width: 100%;
            }
          }
        }
        .right-side {
          .desc {
            width: 90%;
            font-family: "Aeonik Light";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 145%;
            color: #000000;
            @include media("<=phoneH") {
              width: 100%;
            }
          }
        }
      }
    }
    .btm-title {
      font-family: "Neue Leiden Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      color: #000000;
      @include media("<=phoneH") {
        font-size: 15px;
      }
    }
  }
}
