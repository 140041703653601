// Side Bar
.sidebar-s {
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
  height: 100%;
  inset: 0;
  overflow: hidden;
  .side-block {
    width: 260px;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: all 0.3s cubic-bezier(0.9, 0.32, 0.46, 1.18) 0s;
    background-color: #ffffff;
    &::-webkit-scrollbar {
      width: 5px;
    }
    @include media(">=phone", "<=480px") {
      width: 70%;
    }
    &.show {
      left: 0;
      .body {
        overflow-y: hidden;
      }
    }
    &.hide {
      left: -450px;
    }
    .hdr {
      padding: 14px;
      width: 100%;
      min-height: 70px;
      .icon-close {
        height: 20px;
        cursor: pointer;
        svg {
          height: 34px;
          width: 34px;
        }
      }
    }
    .menus-list {
      padding: 0px 16px;
      .menu-item {
        .menu-title {
          font-family: "Aeonik Bold";
          font-size: 20px;
          font-weight: 300;
          color: #000000;
        }
        .sub-menu-list {
          padding: 30px 0;
          .sub-li {
            font-family: "Aeonik Regular";
            font-size: 18px;
            font-weight: 400;
            color: #000000;
            margin-bottom: 12px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .actions {
      gap: 20px;
      padding: 0 16px;
      margin-bottom: 20px;
      .btn-login {
        border-radius: 30px;
        font-size: 16px;
        &:last-child {
          border-color: #000000;
          background: transparent;
          color: #000000;
        }
      }
    }
  }
}
